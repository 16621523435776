import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{isLogin:true},
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta:{isLogin:false},
  },
  {
    path: '/sso',
    name: 'Sso',
    component: () => import('../views/sso.vue'),
    meta:{isLogin:true},
  },
  {
    path: '/reg',
    name: 'Reg',
    component: () => import('../views/Reg.vue'),
    meta:{isLogin:false},
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/Forget.vue'),
    meta:{isLogin:false},
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    meta:{isLogin:true},
  },
  {
    path: '/activate',
    name: 'Activate',
    component: () => import('../views/Activate.vue'),
    meta:{isLogin:false},
  },
  {
    path: '/wechatBind',
    name: 'wechatBind',
    component: () => import('../views/WechatBind.vue'),
    meta:{isLogin:true},
  },
  {
    path: '/wechatCallback/:status',
    name: 'wechatCallback',
    component: () => import('../views/WechatCallback.vue'),
    meta:{isLogin:false},
  },
  {
    path: '/googleCallback/:status',
    name: 'googleCallback',
    component: () => import('../views/GoogleCallback.vue'),
    meta:{isLogin:false},
  },
  {
    path: '/security',
    name: 'security',
    component: () => import('../views/Security.vue'),
    meta:{isLogin:true},
  },
  {
    path: '/admin/clients',
    name: 'adminClients',
    component: () => import('../views/admin/Clients.vue'),
    meta:{isLogin:true},
  },
  {
    path: '/admin/logs',
    name: 'adminLogs',
    component: () => import('../views/admin/Log.vue'),
    meta:{isLogin:true},
  },
  {
    path: '/admin/regCode',
    name: 'regCode',
    component: () => import('../views/admin/Regcode.vue'),
    meta:{isLogin:true},
  },
  {
    path: '/admin/userGroup',
    name: 'userGroup',
    component: () => import('../views/admin/UserGroup.vue'),
    meta:{isLogin:true},
  },
  {
    path: '/admin/users',
    name: 'user',
    component: () => import('../views/admin/Users.vue'),
    meta:{isLogin:true},
  }
]

const router = new VueRouter({
  routes
})

export default router
