import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/axios";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import VueCookies from "vue-cookies";
import vuetify from "./plugins/vuetify";
import md5 from "js-md5";

Vue.config.productionTip = false;
Vue.prototype.cookie = VueCookies;
Vue.prototype.$md5 = md5;

nProgress.configure({ ease: "ease", speed: 500 });

router.beforeEach((to, from, next) => {
  nProgress.start();

  let cookieToken = VueCookies.get("token");

  if (cookieToken == "" || cookieToken == null) {
    store.dispatch("userStatus", false);
    localStorage.setItem("Flag", "");
  } else {
    //将cookietoken存于localstorage
    store.dispatch("userStatus", true);
    localStorage.setItem("Flag", cookieToken);
  }

  //获取用户登录成功后储存的登录标志
  let getFlag = localStorage.getItem("Flag");

  //如果登录标志存在且为isLogin，即用户已登录
  if (getFlag != "" && getFlag != null) {
    //设置vuex登录状态为已登录
    store.state.isLogin = true;
    //console.log("已登录" + getFlag);
    next();
    //如果登录标志不存在，即未登录
  } else {
    //用户想进入需要登录的页面，则定向回登录界面
    if (to.meta.isLogin) {
      var callback = to.fullPath;
      if (to.fullPath == "/logout") {
        callback = "/";
      }
      next({
        path: "/login",
        query: {
          callback: callback,
        },
      });
    } else {
      next();
    }
  }
});
router.afterEach(() => {
  nProgress.done();
});


Vue.prototype.parseError = function (res) {
  if (res == null) {
    return null;
  }
  switch (res.data.status) {
    case -1001:
      store.dispatch("userStatus", false);
      localStorage.removeItem("Flag");
      this.$router.push({
        path: "/login",
        query: {
          callback: this.$route.path,
        },
      });
      return "登录状态无效";
    case -1002:
      return "账号未激活";
    case -1003:
      return "账号被封禁(" + res.data.msg + ")";
    case -1004:
      if (res.data.msg != "") {
        return "参数无效(" + res.data.msg + ")";
      } else {
        return "参数无效";
      }
    case -1005:
      return "无权限访问";
    case -1006:
      return res.data.msg;
    case -1007:
      return "ResErrorNeedCaptcha";
    default:
      return res.data.msg;
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
