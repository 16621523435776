<template>
  <div>
    <v-container>
      <!-- 客户端列表区域 -->
      <v-card class="my-3" v-if="userinfo.from_admin==true">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 pl-0">管理员
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn depressed @click="switch_user" color="primary" class="mx-2"><v-icon>mdi-swap-horizontal</v-icon>切换登录状态</v-btn>
        </v-app-bar>
      </v-card>

      <!-- 主页卡片 -->
      <v-card class="mx-auto mt-5" max-width="344">
        <v-card-title class="">
          <v-avatar size="56">
            <img alt="user" :src="avatar" />
          </v-avatar>
          <div class="ml-3">
            <div>
              {{ userinfo.user_name }}

              <v-chip class="ma-2" color="error" outlined v-if="userinfo.active == 0">
                <v-icon left> mdi-email </v-icon>
                未验证
              </v-chip>
              <v-chip class="ma-2" color="error" outlined v-if="userinfo.active == -1">
                <v-icon left> mdi-close-octagon </v-icon>
                封禁中
              </v-chip>
            </div>
            <div class="text-caption">{{ userinfo.email }}</div>
          </div>
        </v-card-title>

        <v-divider></v-divider>
        <!-- 按钮 -->
        <v-card-actions class="d-flex flex-wrap">
          <v-btn text to="/logout"> 退出登录 </v-btn>
          <v-btn text v-if="userinfo.active == 0" color="primary" @click="resend">
            重发激活邮件</v-btn>
          <v-btn text @click="open_bind_dialog"> 第三方账号绑定 </v-btn>
          <v-btn text to="/security"> 账号安全 </v-btn>
        </v-card-actions>
        <!-- 按钮 -->
        <v-divider></v-divider>

        <!-- 管理员按钮 -->
        <div v-if="userinfo.is_admin == true">
          <v-card class="d-flex flex-wrap" flat>
            <v-btn depressed v-for="(item, i) in admin_btn_list" :key="i" color="primary" :to="item.url"
              class="mx-3 my-2">
              {{ item.name }}
            </v-btn>
          </v-card>
          <v-divider></v-divider>
        </div>
        <!-- 管理员按钮 -->

        <!-- app列表 -->
        <v-card-text>
          <v-list nav dense>
            <v-subheader>APP列表</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in appList.list" :key="i" :href="item.base_url">
                <v-list-item-avatar v-if="item.icon == ''">
                  <v-img max-height="45px" max-width="45px"
                    src="https://web-static-1304188470.file.myqcloud.com/img/icon_default_1.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-avatar v-if="item.icon != ''">
                  <v-img max-height="45px" max-width="45px" :src="item.icon"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.name }} </v-list-item-title>
                  <v-list-item-subtitle>{{ item.base_url }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- 提示框 -->
      <v-dialog v-model="config.alert" max-width="450px">
        <v-card>
          <v-card-title>{{ config.alert_type }}</v-card-title>
          <v-card-text>
            {{ config.alert_text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="config.alert = !config.alert">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 第三方账号绑定列表 -->
      <v-dialog v-model="bind_dialog" max-width="600">
        <v-card>
          <v-card-title>
            <span class="text-h5">第三方账号绑定列表</span>
          </v-card-title>
          <v-card-text>
            <v-list three-line>
              <template v-for="(item, index) in bind_list">
                <v-list-item :key="index">
                  <v-list-item-avatar>
                    <v-icon>{{ item.Icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.ServiceName"></v-list-item-title>
                    <v-list-item-subtitle v-if="item.Status">已绑定用户：{{ item.UserInfo }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn depressed :href="item.BindUrl" v-if="item.BindUrl != ''">
                      绑定
                    </v-btn>
                    <v-btn depressed :href="item.UnBindUrl" v-if="item.UnBindUrl != ''">
                      解除绑定
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="bind_dialog = false">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

  </div>
</template>

<script>
export default {
  name: "Home",
  data: function () {
    return {
      userinfo: {
        user_name: "",
        email: "",
        active: 1,
        group: "",
      },
      config: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      appList: {
        count: 0,
        group: "",
        list: [],
      },
      admin_btn_list: [
        {
          name: "日志聚合平台",
          url: "/admin/logs",
        },
        {
          name: "客户端管理",
          url: "/admin/clients",
        },
        {
          name: "注册码管理",
          url: "/admin/regCode",
        },
        {
          name: "用户群组管理",
          url: "/admin/userGroup",
        },
        {
          name: "用户管理",
          url: "/admin/users",
        }
      ],
      avatar:
        "https://web-static-1304188470.file.myqcloud.com/img/navavatar.png",
      bind_dialog: false,
      bind_list: {},
    };
  },
  mounted: function () {
    //获取用户信息
    this.axios
      .get("/api/user/info")
      .then((res) => {
        if (res.data.status < 0) {
          this.error(this.parseError(res));
          return;
        }
        this.userinfo = res.data.data;
        if (this.userinfo.avatar == "") {
          this.avatar =
            "https://dn-qiniu-avatar.qbox.me/avatar/" +
            this.$md5(this.lower(this.userinfo.email)) +
            "?&d=identicon";
        } else {
          this.avatar = this.userinfo.avatar;
        }

        if (this.userinfo.active == 0) {
          this.config.alert = !this.config.alert;
          this.config.alert_text = "您的账号邮箱尚未验证";
          this.config.alert_type = "注意";
        }
      })
      .catch((err) => {
        console.log(err);
        this.error("发生错误:" + err);
      });

    //获取app列表
    this.axios
      .get("/api/user/appList")
      .then((res) => {
        if (res.data.status < 0) {
          this.error(this.parseError(res));
          return;
        }
        this.appList = res.data.data;
      })
      .catch((err) => {
        console.log(err);
        this.error("发生错误:" + err);
      });
  },
  methods: {
    open_bind_dialog: function () {
      this.axios
        .get("/api/user/bindList")
        .then((res) => {
          if (res.data.status < 0) {
            this.error(this.parseError(res));
            return;
          }
          this.bind_list = res.data.data;
          this.bind_dialog = true;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    switch_user:function(){
      this.axios.post("/api/user/adminSwitch").then((res) => {
        if (res.data.status < 0) {
          this.error(this.parseError(res));
          return;
        }
        this.success(res.data.msg);
        setTimeout(() => {
          this.$router.push("/admin/users");
        }, 1000);
      });
    },
    success: function (text) {
      this.config.alert = !this.config.alert;
      this.config.alert_text = text;
      this.config.alert_type = "成功";
    },
    error: function (err) {
      this.config.alert = !this.config.alert;
      this.config.alert_text = err;
      this.config.alert_type = "错误";
    },
    resend: function () {
      this.axios.post("/api/user/email/resend").then((res) => {
        if (res.data.status < 0) {
          this.error(this.parseError(res));
          this.initError = true;
          return;
        }
        this.success(res.data.msg);
      });
    },
    lower: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toLowerCase();
    },
  },
};
</script>
