import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
  },
  mutations: {
    //获取登录状态
    isLogin: state => state.isLogin,
  },
  actions: {
     //保存登录状态
     userStatus(state, flag) {
      state.isLogin = flag
    },
  },
  modules: {
    //获取登录状态
    userLogin({commit}, flag) {
      commit("userStatus", flag)
    },
  }
})
