<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title @click="go_to_index" style="cursor: pointer;">账号服务</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    go_to_index: function () {
      this.$router.push("/")
    },
  }
};
</script>
